import React from "react";
import "./footer.scss";
import { Link } from  "./link"

export interface FooterProps {
}

export function Footer(props: FooterProps) {

    return (
        <footer>
            <Link
                to={`/resume.pdf`}
                icon="fa-file"
                text="Resume"
                newTab
                nonRouterLink
            />
            <Link
                to={`https://github.com/jwunderl`}
                icon="fa-github-alt"
                text="GitHub"
                newTab
            />
            <Link
                to={`https://www.linkedin.com/in/jwunderl`}
                icon="fa-linkedin-square"
                text="LinkedIn"
                newTab
            />
        </footer>
    );
}