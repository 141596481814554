import React from "react";
import ProfilePhoto from "../static/profile.png"
import "./header.scss";

export interface HeaderProps {
}

export function Header(props: HeaderProps) {

    return (
        <header>
            <img
                src={ProfilePhoto}
                alt="Profile of author"
            />
            <span className="page-header">jwunderl</span>
        </header>
    );
}