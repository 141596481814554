import React from 'react';
import "./labeled-text-input.scss"

export interface LabeledTextInputProps {
    label: string;
    value?: string | number;
    onChange?: () => void;
}

export class LabeledTextInput extends React.Component<LabeledTextInputProps, {}> {
    render() {
        const {
            label,
            value,
            onChange,
        } = this.props;

        const isReadOnly = !onChange;

        return <div className="labeled-input">
            <input
                type="text"
                value={value}
                onChange={onChange}
                readOnly={isReadOnly} />
            <label>{label}</label>
        </div>;
    }
}
