import React from "react";
import {
    Switch,
    Route,
    useLocation
} from "react-router-dom"
import { TransitionGroup, CSSTransition } from "react-transition-group";

import "./routing.scss"

import { Home } from "./home";
import { Examples } from "./examples";
import { FourOhFour } from "./four-oh-four"
import { HomeButton } from "./home-button";
import { Extensions } from "./extensions";
import { FullScreenExampleView } from "./full-screen-example-view";
import { ColorPicker, HueColorPicker } from "./color-picker/color-picker";

export interface MainContentProps {
}

export function MainContent(props: MainContentProps) {
    const location = useLocation();
    return (
        <main className="content">
            <TransitionGroup id="main-content-transition">
                <CSSTransition
                    key={location.key}
                    timeout={{ enter: 350 }}
                    classNames={'fade'}
                >
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/fs/:id/:repo" component={FullScreenExampleView} />
                        <Route path="/examples" component={Examples} />
                        <Route path="/extensions" component={Extensions} />
                        <Route path="/resume" component={
                            () => {
                                window.location.replace('/resume.pdf');
                                return <p>Redirecting</p>;
                            }
                        }/>
                        <Route path="/color-picker" component={ColorPicker} />
                        <Route path="/hue-color-picker" component={
                            () => <HueColorPicker selectHue />}
                        />
                        <Route path="*" component={FourOhFour} />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
            <HomeButton />
        </main>
    );
}
