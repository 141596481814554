import React from 'react';

import "./link.scss"

import { FontAwesomeIcon } from "./fa-icon"
import { Link as ReactRouterLink } from "react-router-dom";

export interface LinkProps {
    to: string;

    icon?: string;
    text?: string;

    ariaLabel?: string;

    className?: string;
    id?: string;

    children?: any;

    button?: boolean;
    newTab?: boolean;
    nonRouterLink?: boolean;
}

export function Link(props: LinkProps) {
    const {
        ariaLabel,
        button,
        children,
        className,
        icon,
        id,
        newTab,
        nonRouterLink,
        text,
        to
    } = props;

    const isNormalAnchor = nonRouterLink || /^https?:\/\//i.test(to);
    return (
        isNormalAnchor ?
            <a
                href={to}
                className={`${className || ""} ${button ? "button" : ""} ${icon ? "icon" : ""}`}
                id={id}
                target={newTab ? "_blank" : undefined}
                rel={newTab ? "noopener noreferrer" : undefined}
                aria-label={ariaLabel}
            >
                {icon && <FontAwesomeIcon icon={icon} />}
                {text}
                {children}
            </a>
            :
            <ReactRouterLink
                to={to}
                className={`${className || ""} ${button ? "button" : ""}`}
                id={id}
                target={newTab ? "_blank" : undefined}
                rel={newTab ? "noopener noreferrer" : undefined}
                aria-label={ariaLabel}
            >
                {icon && <FontAwesomeIcon icon={icon} />}
                {text}
                {children}
            </ReactRouterLink>
    );
}