import React, { createRef } from 'react';
import { clamp } from '../../utils/browser-utils';
import "./color-picker.scss"
import { createHexColor, CanvasFillStyle, Point, RGB } from './util';

export interface ColorCanvasProps {
    layers: (canvas: HTMLCanvasElement) => CanvasFillStyle[];
    initialPosition?: Point;
    onChange?: (color: RGB) => void;
    // TODO better way to handle this? If not, need to add unsubscribe and handle registration in props change.
    redrawSubscriber?: (redraw: () => void) => void;
    selectorStyle: "circle" | "vertical-bar" | "none";
}

export interface ColorCanvasState {
    selection: Point;
    copied?: boolean;
}

export class ColorCanvas extends React.Component<ColorCanvasProps, ColorCanvasState>{
    protected canvasRef: React.RefObject<HTMLCanvasElement>;
    protected ctx?: CanvasRenderingContext2D;
    protected lastSelection?: RGB;

    static defaultProps = {
        selectorStyle: "circle",
    }

    constructor(public props: ColorCanvasProps) {
        super(props);

        this.canvasRef = createRef();

        const {
            initialPosition
        } = props;


        this.state = {
            selection: initialPosition || { x: 50, y: 50 },
        };

        this.props.redrawSubscriber?.(this.redrawCanvas);
    }

    componentDidMount() {
        // canvas is rendered unconditionally
        const canvas = this.canvasRef.current!;
        this.ctx = canvas.getContext("2d")!;

        this.redrawCanvas();

        // Registered on document to catch up events outside canvas area
        document.addEventListener("pointerup", this.canvasUp);
        document.addEventListener("touchend", this.canvasUp);
        document.addEventListener("pointermove", this.canvasMove);
        document.addEventListener("touchmove", this.canvasMove);
    }

    componentWillUnmount() {
        document.removeEventListener("pointerup", this.canvasUp);
        document.removeEventListener("touchend", this.canvasUp);
        document.removeEventListener("pointermove", this.canvasMove);
        document.removeEventListener("touchmove", this.canvasMove);
    }

    protected fixDPI = (canvas: HTMLCanvasElement, computed: CSSStyleDeclaration) => {
        const dpi = window.devicePixelRatio || 1;
        canvas.setAttribute('width', parseFloat(computed.width) * dpi + "px");
        canvas.setAttribute('height', parseFloat(computed.height) * dpi + "px");
    }

    protected redrawCanvas = () => {
        const {
            layers,
        } = this.props;

        const canvas = this.canvasRef.current!;
        const computed = getComputedStyle(canvas);
        this.fixDPI(canvas, computed);

        for (const gradient of layers(canvas)) {
            this.renderLayers(canvas, this.ctx!, gradient);
        }

        this.drawSelection();
    }

    protected drawSelection = () => {
        const dpi = window.devicePixelRatio || 1;
        const selectedColor = createHexColor(this.getSelection());

        switch (this.props.selectorStyle) {
            case "none":
                // no preview
                break;
            case "vertical-bar":
                this.drawBarSelection(
                    selectedColor,
                    dpi,
                );
                break;
            case "circle":
            default:
                this.drawArcSelection(
                    selectedColor,
                    dpi,
                );
                break;
        }

    }

    protected drawArcSelection = (color: string, dpi: number) => {
        const { selection } = this.state;
        const ctx = this.ctx!;
        const x = selection.x * dpi;
        const y = selection.y * dpi;

        ctx.arc(
            x, y,
            /** radius **/ 7 * dpi,
            0, Math.PI * 2
        );

        ctx.fillStyle = color;
        ctx.fill();

        ctx.strokeStyle = "black";
        ctx.lineWidth = 3 * dpi;
        ctx.stroke();

        ctx.strokeStyle = "#C0C0C0";
        ctx.lineWidth = dpi;
        ctx.stroke();

        ctx.closePath();
    }

    protected drawBarSelection = (color: string, dpi: number) => {
        const { selection } = this.state;
        const ctx = this.ctx!;
        const x = selection.x * dpi;
        const w = 4 * dpi;

        const canvas = this.canvasRef.current!;

        ctx.rect(
            x - w,
            0,
            w * 2,
            canvas.height
        );
        ctx.fillStyle = color;
        ctx.fill();

        ctx.strokeStyle = "black";
        ctx.lineWidth = 2 * dpi;
        ctx.stroke();

        ctx.closePath();
    }

    protected renderLayers = (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, grad: CanvasFillStyle) => {
        ctx.fillStyle = grad;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
    }

    protected getSelection = () => {
        const {
            selection,
        } = this.state;

        const {
            onChange,
        } = this.props;

        const dpi = window.devicePixelRatio || 1;

        const x = selection.x * dpi;
        const y = selection.y * dpi;

        const color = this.ctx!.getImageData(x, y, 1, 1).data;

        const current = {
            r: color[0],
            g: color[1],
            b: color[2],
            a: color[3]
        };
        if (!this.lastSelection
            || current.r !== this.lastSelection.r
            || current.g !== this.lastSelection.g
            || current.b !== this.lastSelection.b
            || current.a !== this.lastSelection.a) {
            onChange?.(current);
        }

        return current;
    }

    protected _pointerIsDown = false;
    protected canvasDown = (e: React.PointerEvent | React.TouchEvent) => {
        this._pointerIsDown = true;
        this.canvasMoveSelection(e);
    }

    protected canvasMove = (e: PointerEvent | TouchEvent) => {
        if (this._pointerIsDown) {
            this.canvasMoveSelection(e);
        }
    }

    protected canvasUp = () => {
        this._pointerIsDown = false;
    }

    protected canvasMoveSelection = (e: React.PointerEvent | React.TouchEvent | PointerEvent | TouchEvent) => {
        const canvas = this.canvasRef.current!;
        const dpi = window.devicePixelRatio || 1;

        let x: number;
        let y: number;

        if ((e as React.TouchEvent).touches) {
            const touch = (e as React.TouchEvent).touches[0];
            x = touch.clientX;
            y = touch.clientY;
        } else {
            x = (e as React.PointerEvent).clientX;
            y = (e as React.PointerEvent).clientY;
        }

        x -= canvas.offsetLeft;
        y -= canvas.offsetTop;

        const selection = {
            x: clamp(0, (canvas.width - 1) / dpi, x),
            y: clamp(0, (canvas.height - 1) / dpi, y),
        }

        this.setState({
            selection
        }, this.redrawCanvas);
    }

    render() {
        return (
            <canvas
                className="color-picker-canvas"
                ref={this.canvasRef}
                onPointerDown={this.canvasDown}
                onTouchStart={this.canvasDown}
            />
        );
    }
}