import React from 'react';
import "./arcade-simulator.scss"

export interface ArcadeSimulatorProps {
    username: string;
    repo: string;
    title?: string;
    largeView?: boolean;
}

export function ArcadeSimulator(props: ArcadeSimulatorProps) {
    const {
        largeView,
        repo,
        title,
        username
    } = props;

    return (
        <div className={`arcade-sim-frame ${largeView ? "large-view" : ""}`}>
            <iframe
                src={`https://${username}.github.io/${repo}?embed=1&nofooter=1`}
                allowFullScreen
                sandbox="allow-popups allow-forms allow-scripts allow-same-origin"
                frameBorder={0}
                title={title ?? "MakeCode Arcade Simulator"}
            />
        </div>
    );
}