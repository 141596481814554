


export type Point = { x: number, y: number };

export type CanvasFillStyle = string | CanvasGradient | CanvasPattern;

export type HSL = { h: number, s: number, l: number, a?: number };
export type RGB = { r: number, g: number, b: number, a?: number }

export function createHexColor(rgb: RGB | undefined): string {
    if (!rgb) {
        return "#000000";
    }
    const {
        r,
        g,
        b,
        a
    } = rgb;
    const hasAlpha = a !== undefined;

    return `#${toHexByte(r)}${toHexByte(g)}${toHexByte(b)}${hasAlpha ? toHexByte(a!) : ""}`;

}

export function toHexByte(num: number) {
    const asHex = (num | 0).toString(16).toUpperCase();
    return asHex.length === 1 ? `0${asHex}` : asHex;
}

export function hexToRgb(hex: string) {

}

// https://github.com/jwunderl/pxt-color/blob/a656c93ec8b553923d2d890b405a8485d77de71b/color.ts#L140
export function rgbToHsl(rgb: RGB): HSL {
    const r = rgb.r / 255;
    const g = rgb.g / 255;
    const b = rgb.b / 255;

    const max = Math.max(Math.max(r, g), b);
    const min = Math.min(Math.min(r, g), b);

    let diff = max - min;
    let h: number;
    if (diff === 0)
        h = 0;
    else if (max === r)
        h = (g - b) / diff % 6;
    else if (max === g)
        h = (b - r) / diff + 2;
    else // if (max === b)
        h = (r - g) / diff + 4;

    let l = (min + max) / 2;
    let s = diff === 0
        ? 0
        : diff / (1 - Math.abs(2 * l - 1));

    h = Math.round(h * 60);
    if (h < 0)
        h += 360;
    return {
        h: h,
        s: s * 100,
        l: l * 100,
        a: rgb.a,
    };
}

// https://github.com/jwunderl/pxt-color/blob/a656c93ec8b553923d2d890b405a8485d77de71b/color.ts#L108
export function hslToRgb(hsl: HSL): RGB {
    let {
        h,
        s,
        l,
        a,
    } = hsl;
    s /= 100;
    l /= 100;
    const chroma = (1 - Math.abs(2 * l - 1)) * s;
    const hp = h / 60.0;
    // second largest component of this color
    const x = chroma * (1 - Math.abs((hp % 2) - 1));

    // 'point along the bottom three faces of the RGB cube'
    let rgb1: number[];
    if (h === undefined)
        rgb1 = [0, 0, 0];
    else if (hp <= 1)
        rgb1 = [chroma, x, 0];
    else if (hp <= 2)
        rgb1 = [x, chroma, 0];
    else if (hp <= 3)
        rgb1 = [0, chroma, x];
    else if (hp <= 4)
        rgb1 = [0, x, chroma];
    else if (hp <= 5)
        rgb1 = [x, 0, chroma];
    else //  if (hp <= 6)
        rgb1 = [chroma, 0, x];

    // lightness match component
    let m = l - chroma * 0.5;

    return {
        r: Math.round(255 * (rgb1[0] + m)),
        g: Math.round(255 * (rgb1[0] + m)),
        b: Math.round(255 * (rgb1[0] + m)),
        a: a
    };
}