import React from "react";
import { ArcadeSimulator } from "./arcade-simulator";
import { useParams } from "react-router-dom";
import { FourOhFour } from "./four-oh-four";

export function FullScreenExampleView() {
    const { id, repo } = useParams();
    if (id?.toLowerCase() !== "microsoft" && id?.toLowerCase() !== "jwunderl") {
        return <FourOhFour />
    }

    // id and repo will be defined if we reach this point by the router
    return (
        <ArcadeSimulator username={id!} repo={repo!} title="Football Game" largeView />
    );
}
