import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "./link"
import "./home-button.scss"

export function HomeButton() {
    const location = useLocation();
    if (location.pathname === "/")
        return null;

    return (
        <Link
            to="/"
            id="go-home-link"
            icon="fa-home"
            ariaLabel="Go to homepage"
        />
    );
};
