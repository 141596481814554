import React, { createRef } from 'react';
import { delay, throttle } from '../../utils/browser-utils';
import { ColorCanvas } from './color-canvas';
import "./color-picker.scss"
import { LabeledTextInput } from './labeled-text-input';
import { CanvasFillStyle, createHexColor, Point, RGB, rgbToHsl, toHexByte } from './util';

export interface ColorPickerProps {
    selectHue?: boolean;
    initialPosition?: Point;
    showAlpha?: boolean;
}

// TODO: probably switch internal repr to HSL from RGB?
// Or maybe just directly use position on bar

export interface ColorPickerState {
    selection: Point;
    currentColor?: RGB;
    currentHueColor?: string;
    copied?: boolean;
}

// export class ColorPicker<P extends ColorPickerProps, S extends ColorPickerState> extends React.Component<ColorPickerProps, ColorPickerState>{
export class ColorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
    protected previewRef: React.RefObject<HTMLDivElement>;
    protected ctx?: CanvasRenderingContext2D;

    constructor(public props: ColorPickerProps) {
        super(props);

        this.previewRef = createRef();

        const {
            initialPosition
        } = props;

        this.state = {
            selection: initialPosition || { x: 50, y: 50 },
        };
    }

    protected fixDPI = (canvas: HTMLCanvasElement, computed: CSSStyleDeclaration) => {
        const dpi = window.devicePixelRatio || 1;
        canvas.setAttribute('width', parseFloat(computed.width) * dpi + "px");
        canvas.setAttribute('height', parseFloat(computed.height) * dpi + "px");
    }

    protected createCanvasLayers: (canvas: HTMLCanvasElement) => CanvasFillStyle[] = (canvas: HTMLCanvasElement) => {
        return [
            this.createHorizontalColorGradient,
            this.createVerticalAlphaGradient,
        ].map(fill => fill(canvas));
    }

    protected createHueLayers: (canvas: HTMLCanvasElement) => CanvasFillStyle[] = (canvas: HTMLCanvasElement) => {
        return [
            this.createHorizontalColorGradient,
        ].map(fill => fill(canvas));
    }

    protected createHorizontalColorGradient = (canvas: HTMLCanvasElement) => {
        const ctx = canvas.getContext("2d")!;
        const grad = ctx.createLinearGradient(0, 0, canvas.width - 1, 0);

        const colorStops = [
            "#FF0000",
            "#FF00FF",
            "#0000FF",
            "#00FFFF",
            "#00FF00",
            "#FFFF00",
            "#FF0000",
        ];

        for (let i = 0; i < colorStops.length; i++) {
            grad.addColorStop(i / (colorStops.length - 1), colorStops[i])
        }

        return grad;
    }

    protected createVerticalAlphaGradient = (canvas: HTMLCanvasElement) => {
        const ctx = canvas.getContext("2d")!;
        const grad = ctx.createLinearGradient(0, 0, 0, canvas.height - 1);

        grad.addColorStop(0.0, "#FFFFFFFF");
        grad.addColorStop(0.5, "#FFFFFF00");
        grad.addColorStop(0.5, "#00000000");
        grad.addColorStop(1.0, "#000000FF");

        return grad;
    }

    protected throttledUpdateCurrentColor = throttle((color: RGB) => {
        this.setState({
            currentColor: color,
            copied: false,
        });
    }, 100, true);

    protected onColorCanvasChange = (color: RGB) => {
        if (this.previewRef.current) {
            // give immediate preview in bubble
            this.previewRef.current.style.backgroundColor = createHexColor(color);
        }
        this.throttledUpdateCurrentColor(color)
    }

    // TODO: subclass only for now
    protected onHueCanvasChange = (color: RGB) => { }

    protected outputClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        try {
            // TODO: need to add back way to fetch most up to date color from ColorCanvas;
            // this could theoretically be out of date by ~100ms
            // const color = this.state.currentColor!;
            const {
                r,
                g,
                b,
                a
            } = this.state.currentColor!;
            const toCopy = createHexColor({
                r: r,
                g: g,
                b: b,
                a: a !== 255 ? a : undefined
            });
            await navigator.clipboard.writeText(toCopy);
            this.setState({ copied: true });
            await delay(1000);
            this.setState({ copied: false });
        } catch { /** Non terminal error **/ }
    }

    protected primaryCanvasRedraw?: () => void;
    protected primaryCanvasRedrawSubscriber = (handler: () => void) => {
        this.primaryCanvasRedraw = handler;
    }

    render() {
        const {
            selectHue,
            showAlpha,
        } = this.props;

        const {
            currentColor,
            copied,
        } = this.state;

        const colorParts = [];
        if (currentColor) {
            colorParts.push(
                "#",
                toHexByte(currentColor.r), // r
                toHexByte(currentColor.g), // g
                toHexByte(currentColor.b), // b
                showAlpha && currentColor.a ? toHexByte(currentColor.a) : undefined, // a
            );
        }

        const previewStyle: React.CSSProperties = {
            backgroundColor: createHexColor(currentColor!),
        };

        const colorAsHSL = currentColor && rgbToHsl(currentColor);

        return (
            <div className="color-picker">
                <div className="primary">
                    <div className="color-picker-primary-surface">
                        <ColorCanvas
                            layers={this.createCanvasLayers}
                            onChange={this.onColorCanvasChange}
                            redrawSubscriber={this.primaryCanvasRedrawSubscriber}
                        />
                    </div>
                    {selectHue && <div className="color-picker-hue-bar">
                        <ColorCanvas
                            layers={this.createHueLayers}
                            onChange={this.onHueCanvasChange}
                            initialPosition={{ x: 0, y: 10 }}
                            selectorStyle="vertical-bar"
                        />
                    </div>}
                    <div
                        className="color-picker-output"
                        onClick={this.outputClick}
                    >
                        {copied ?
                            <div>Copied!</div>
                            :
                            <div className="color-picker-current">
                                <span>{colorParts[0]}</span>
                                <span>{colorParts[1]}</span>
                                <span>{colorParts[2]}</span>
                                <span>{colorParts[3]}</span>
                                <span>{colorParts[4]}</span>
                            </div>
                        }
                        <div
                            className="color-picker-preview"
                            style={previewStyle}
                            ref={this.previewRef}
                        />
                    </div>
                </div>
                <div
                    className="inputs"
                >
                    <LabeledTextInput
                        label="H"
                        value={colorAsHSL ? colorAsHSL.h : 0}
                    />
                    <LabeledTextInput
                        label="S"
                        value={colorAsHSL ? (Math.round(colorAsHSL.s) + "%") : 0}
                    />
                    <LabeledTextInput
                        label="L"
                        value={colorAsHSL ? (Math.round(colorAsHSL.l) + "%") : 0}
                    />
                </div>
            </div>
        );
    }
}

// TODO: Figure out to to make typings work to be able to extend state here
// export class HueColorPicker<P, S = HueColorPickerState> extends ColorPicker<ColorPickerProps, HueColorPickerState> {
export class HueColorPicker extends ColorPicker {

    constructor(props: ColorPickerProps) {
        super(props);
        this.state = {
            ...this.state,
            currentHueColor: "#FF0000",
        };
    }
    protected createCanvasLayers = (canvas: HTMLCanvasElement) => {
        return [
            this.createHueBackground,
            this.createHorizontalWhiteAlphaGradient,
            this.createVerticalBlackAlphaGradient
        ].map(fill => fill(canvas));
    }

    protected createHueBackground = () => {
        return this.state.currentHueColor!;
    }

    protected createHorizontalWhiteAlphaGradient = (canvas: HTMLCanvasElement) => {
        const ctx = canvas.getContext("2d")!;
        const grad = ctx.createLinearGradient(0, 0, canvas.width - 1, 0);

        grad.addColorStop(0, "#FFFFFFFF");
        grad.addColorStop(1, "#FFFFFF00");

        return grad;
    }

    protected createVerticalBlackAlphaGradient = (canvas: HTMLCanvasElement) => {
        const ctx = canvas.getContext("2d")!;
        const grad = ctx.createLinearGradient(0, 0, 0, canvas.height - 1);

        grad.addColorStop(0, "#00000000");
        grad.addColorStop(1, "#000000FF");

        return grad;
    }

    protected onHueCanvasChange = (color: RGB) => {
        this.setState({
            currentHueColor: createHexColor(color),
        }, this.primaryCanvasRedraw);
    }
}
