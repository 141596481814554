import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import "font-awesome/scss/font-awesome.scss";
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom"

ReactDOM.render(
    <Router basename={process.env.PUBLIC_URL}>
        <App />
    </Router>
, document.getElementById('root'));


// skip the waiting period
serviceWorker.register({
    onUpdate: registration => {
        const { waiting } = registration;

        if (waiting) {
            waiting.addEventListener(
                "statechange",
                ev => {
                    if ((ev.target as any).state === "activated") {
                        window.location.reload();
                    }
                }
            );
            waiting.postMessage({ type: "SKIP_WAITING" });
        }
    }
});
