import React from "react";
import { SimulatorCard } from "./simulator-card";
import { Link } from "./link";

export function Examples() {
    return (
        <div>
            <SimulatorCard
                username="jwunderl"
                repo="pxt-fireworks"
                header="Fireworks!"
            >
                <p>
                    This is a fireworks particle effects example I made for the Fourth of July.
                </p>
                <p>
                    The source code is available on the MakeCode Arcade homepage under 'Graphics and Math'
                    <Link newTab to="https://arcade.makecode.com/beta#example:/graphics-math/fireworks"> (link)</Link>.
                    That example includes documentation on how the particle effects system is implemented as well.
                </p>
                <p>
                    This example also uses the <Link to="/extensions#pxt-color-simulator-card">Color Fading</Link> extension
                    to set random pastel (ish) colors, so that each firework is unique in shape and color.
                </p>
            </SimulatorCard>
            <SimulatorCard
                username="microsoft"
                repo="pxt-kickoff"
                header="NFL Football Collaboration"
            >
                <p>
                    This was made for use in workshops in Microsoft Stores,
                    in a collaboration between Microsoft Surface and the NFL.
                </p>
                <p>
                    It is almost entirely built as an extension with a few blocks to set options;
                    for example, changing the teams or inverting the team colors.
                </p>
                <p>
                    I also put together a simple page for these lessons that you can see at <Link newTab to="https://arcade.makecode.com/nfl">arcade.makecode.com/nfl</Link>;
                    that includes a guided tutorial in step 2 on 'modding' the game.
                </p>
            </SimulatorCard>
            <SimulatorCard
                username="jwunderl"
                repo="arcade-sorting-algorithms"
                header="Sorting Algorithms"
            >
                <p>
                    This example shows a random selection of sorting algorithms running on the same random input
                    (with arbitrary yields to allow each algorithm to run and update 'simultaneously').
                </p>
                <p>
                    The up and down buttons will change the number of elements being sorted,
                    the left and right buttons will change the number of algorithms running at once,
                    and the A button will restart with a new input set.
                </p>
                <p>
                    This example is also available on MakeCode Arcade's <Link newTab to="https://arcade.makecode.com/">homepage </Link>
                    in the 'Graphics and Math' carousel. I definitely took some inspiration from <Link newTab to="https://www.toptal.com/developers/sorting-algorithms">Toptal's sorting algorithm page</Link>,
                    which I regularly used as an example when I was a TA at the University of Washington.
                </p>
            </SimulatorCard>
            <SimulatorCard
                username="jwunderl"
                repo="arcade-hot-air-balloon"
                header="Hot Air Balloon Ride"
            >
                <p>
                    This one's just a nice, simple hot air balloon ride made in blocks.
                </p>
                <p>
                    It's listed in the 'Blocks Games' category on the MakeCode Arcade <Link newTab to="https://arcade.makecode.com/">homepage</Link>.
                </p>
            </SimulatorCard>
            <SimulatorCard
                username="jwunderl"
                repo="arcade-paint-brush"
                header="Random Painting"
            >
                <p>
                    This example is just a paint brush bouncing around the screen,
                    with it's path traced with a random color.
                </p>
                <p>
                    The interaction in this one is pretty limited;
                    if you press A, it will switch between a 'small' trail and a large one.
                </p>
                <p>
                    This example is also listed on the <Link newTab to="https://arcade.makecode.com/">homepage </Link>
                    under 'Graphics and Math';
                    it's one of the simpler JavaScript examples on the site.
                </p>
                <p>
                    (Also, if you spent a bit of time looking at previous examples,
                    it's likely that you have a unique (counterfeit) Jackson Pollock
                    being finished on the screen by now).
                </p>
            </SimulatorCard>
        </div>
    );
}
