import React from "react";
import "./App.scss";
import { Header } from "./components/header"
import { MainContent } from  "./components/routing"
import { Footer } from "./components/footer"

export function App() {
    return (
        <div className="App">
            <Header />
            <MainContent />
            <Footer />
        </div>
    );
}
