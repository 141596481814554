import React from "react";
import { ArcadeSimulator } from "./arcade-simulator";

import "./simulator-card.scss"
import { Link } from "./link";

interface SimulatorCardProps {
    username: string;
    repo: string;

    header: string;
    children?: any;
}

export function SimulatorCard(props: SimulatorCardProps) {
    const { children, header, repo, username } = props;
    const slug = `${username}/${repo}`;
    const src = `https://github.com/${slug}`;

    return (
        <div className="simulator-card" id={`${repo}-simulator-card`}>
            <Link
                to={`/fs/${slug}`}
                icon="fa-expand"
                className="expand-button"
                ariaLabel={`Expand simulator for ${repo} extension simulator`}
            />
            <h1>
                <Link newTab to={src}>
                    {header}
                </Link>
            </h1>
            <div className="simulator-card-content">
                <ArcadeSimulator
                    repo={repo}
                    username={username}
                    title={`Test code for ${repo} extension`}
                />
                <div className="simulator-card-description">
                    {children}
                </div>
            </div>
        </div>
    );
}
