import React from "react";

export interface FontAwesomeIconProps {
    icon: string;
}

export function FontAwesomeIcon(props: FontAwesomeIconProps) {
    const { icon } = props;

    return <i className={`fa ${icon}`} />
}