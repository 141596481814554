import React from "react";
import { Link } from "./link";

import "./link.scss";

export function Home() {
    return (
        <div id="Home">
            <p>Hi, I'm Joey!</p>
            <p>I'm a Software Engineer @ Microsoft,</p>
            <p>working on <Link to="https://makecode.com">Microsoft MakeCode</Link>.</p>
            <p>Here's some stuff I've done:</p>

            <Link className="button" to="/extensions">MakeCode Arcade Extensions</Link>
            <Link className="button" to="/examples">Arcade Games and Examples</Link>
        </div>
    );
}
