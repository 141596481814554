import React from "react";

import { Link } from "./link";
import { SimulatorCard } from "./simulator-card";

export function Extensions() {
    return (
        <div id="extensions">
            <SimulatorCard
                username="jwunderl"
                repo="pxt-color"
                header="Color Fading"
            >
                <p>
                    MakeCode Arcade is limited to just 15 colors at a time (plus clear).
                </p>
                <p>
                    This can be changed in the project settings file (<code>pxt.json</code>),
                    but that's not particularly easy or clear for users who are just starting to use blocks.
                </p>
                <p>
                    This extension adds a new category of blocks that allows the user to change these
                    colors at runtime in various ways:
                </p>
                <ul>
                    <li>Use predefined palettes</li>
                    <li>Use effects to modify the current palette</li>
                    <li>Define their own colors with RGB or HSL</li>
                </ul>
            </SimulatorCard>
            <SimulatorCard
                username="jwunderl"
                repo="pxt-promises"
                header="ES6(ish) Promises"
            >
                <p>
                    MakeCode's
                    <Link newTab to="https://makecode.com/language"> implementation of JavaScript </Link>
                    diverges from normal JavaScript in it's handling of
                    <Link newTab to="https://makecode.com/async"> multithreading.  </Link>
                    This is intended to help ease in new developers who are just learning the language.
                </p>
                <p>
                    This extension adds in a (reasonably accurate) implementation of ES6 promises (no new blocks).
                </p>
                <p>
                    There are a few snags -
                    for example, promisify is not currently possible to implement,
                    and the current release of arcade limits <code>Thenable</code> to nominal typing
                    - but it will do for a quick dip into Promises,
                    or the occasional need for <br /> <code>Promise.(all|allSettled|race)</code>.
                </p>
            </SimulatorCard>
            <SimulatorCard
                username="jwunderl"
                repo="pxt-scaling"
                header="Image Scaling and Rotation (beta)"
            >
                <p>
                    This one's a bit hot off the presses, but it's a start.
                </p>
                <p>
                    This extension adds a few image helper functions that are highly requested -
                    in particular, scaling and rotating sprites.
                </p>
                <p>
                    There's definitely still work to be done
                    (for example, increasing size a bit when rotating to avoid clipping on the sides,
                    and speeding up the non-trivial rotations so they can ideally work on hardware to some degree).
                </p>
                <p>
                    The implementation is also a bit messy, but I do like the how the test cases look :).
                </p>
            </SimulatorCard>
            <SimulatorCard
                username="microsoft"
                repo="pxt-kickoff"
                header="PXT Kickoff!"
            >
                <p>
                    This extension is actually just a wrapper around a full game;
                    it adds a few blocks to the toolbox
                    that allow you to customize details of the game like which teams are playing,
                    how long the game should last, and so on.
                </p>
                <p>
                    This was made for use in workshops in Microsoft Stores,
                    in a collaboration between Microsoft Surface and the NFL.
                </p>
                <p>
                    I also put together a simple page for these lessons that you can see at <Link newTab to="https://arcade.makecode.com/nfl">arcade.makecode.com/nfl</Link>;
                    that includes a guided tutorial in step 2 on 'modding' the game.
                </p>
            </SimulatorCard>
            <SimulatorCard
                username="jwunderl"
                repo="pxt-button-combos"
                header="Button Combo Handling"
            >
                <p>
                    MakeCode Arcade has events to handle when buttons are pressed, held, or released.
                    This makes it easy to create simple games - move left when left is pressed, and fire when A is pressed.
                </p>
                <p>
                    However, it can become complicated when you start wanting to keep track of sequences of buttons;
                    for example, in a fighting game, you might want to do a normal kick when B is pressed by itself,
                    and a high kick when B is pressed after A.
                </p>
                <p>
                    This extension adds events and other blocks that allow you to easily handle arbitrary button combinations in your game.
                </p>
                <p>
                    More details are available on the forum post <Link newTab to="https://forum.makecode.com/t/combo-attack-and-button-combination-extension/169">here.</Link>
                </p>
            </SimulatorCard>
            <SimulatorCard
                username="jwunderl"
                repo="pxt-status-bar"
                header="Status Bars"
            >
                <p>
                    This extension adds blocks to create and manage status bars.
                </p>
                <p>
                    There are lots of options for customizing the status bars,
                    and they can be attached to sprites as they move around the screen.
                </p>
                <p>
                    This can be helpful in particular for enemy sprites - there is no built in 'sprite life',
                    so using the status bar (and the events associated with it) can allow you to give enemies
                    health or any other (numerical) state,
                    with an instant way to show it.
                </p>
                <p>
                    Additionally, the status bars you create are subclasses of Sprite;
                    this means that you can do anything with a status bar that you can with a Sprite,
                    like giving it a velocity or bouncing it around the screen.
                </p>
            </SimulatorCard>
        </div>
    );
}