import React from "react";
import { useLocation } from "react-router-dom"

export function FourOhFour() {
    const location = useLocation();
    return (
        <div id="404">
            <p>hm, that's a 404; nothing to match</p>
            <pre>{location.pathname}</pre>
        </div>
    )
}