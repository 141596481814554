
export function debounce<F extends (...params: any[]) => void>(
    func: F,
    wait: number,
    immediate?: boolean
) {
	let timeout: any;
	return (...args: Parameters<F>) => {
		const later = function() {
			timeout = null;
            if (!immediate)
                func(...args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
        if (callNow)
            func(...args);
	};
};

export function throttle<F extends (...params: any[]) => void>(
    func: F,
    timeout: number,
    lastCall?: boolean
) {
    let ready: boolean = true;
    let trailingInvocationParameters: Parameters<F> | undefined;
    return (...args: Parameters<F>) => {
        if (!ready) {
            if (lastCall) {
                trailingInvocationParameters = args;
            }
            return;
        }

        ready = false;
        func(...args);
        setTimeout(() => {
            if (trailingInvocationParameters) {
                func(...trailingInvocationParameters);
                trailingInvocationParameters = undefined;
            }
            ready = true;
        }, timeout);
    };
}

export function clamp(min: number, max: number, input: number) {
    return Math.max(min, Math.min(max, input));
}

export async function delay<T>(duration: number, value: T | Promise<T>): Promise<T>;
export async function delay(duration: number): Promise<void>
export async function delay<T>(duration: number, value?: T | Promise<T>): Promise<T> {
    const output = await value;
    await new Promise(resolve => setTimeout(() => resolve(), duration));
    return output!;
}